import { FormikValues } from 'formik'
import CustomInputField from '../shared/customInputField/CustomInputField'
import styles from './../gettingStart/GettingStart.module.scss'
import CustomDropdown from '../shared/CustomDropdown/customDropdown'

interface AddressValues {
  streetAddress?: string
  suburb?: string
  state?: string
  postalCode?: string
  country?: string
}

interface Props {
  values: AddressValues
  setFieldValue: (field: string, target: any) => void
}

export const Address: React.FC<Props> = ({ values, setFieldValue }) => {
  const countryList = [
    {
      text: 'Australia',
      value: 'Australia',
    },
    {
      text: 'Other',
      value: 'Other',
    },
  ]

  return (
    <div
      className={styles.locationSearchWidth}
      style={{ width: '100%', flexDirection: 'column' }}
    >
      <h6 className={styles.widthLeft}>Location*</h6>
      <p className={'mt-3 mb-3 font-weight-bold'}>
        You must be an Australian resident or have a valid Australian work Visa
        to be considered. We currently do not cater for any work Visa
        sponsorships.
      </p>
      <div className="mb-3">
        <CustomDropdown
          name="country"
          dataList={countryList}
          placeHolder="Country *"
          getSelectedItem={(item: any) => {
            setFieldValue('country', item.value)
          }}
          selectedValue={{
            value: values.country ?? '',
            text: values.country ?? '',
          }}
        />
      </div>
      <div className="mb-3">
        <CustomInputField
          name="streetAddress"
          placeholder="Street Address *"
          onChange={(event: FormikValues) =>
            setFieldValue('streetAddress', event.target.value)
          }
          required
          value={values.streetAddress}
        />
      </div>
      <div className="mb-3">
        <CustomInputField
          name="suburb"
          placeholder="Suburb *"
          onChange={(event: FormikValues) =>
            setFieldValue('suburb', event.target.value)
          }
          required
          value={values.streetAddress}
        />
      </div>
      <div className="mb-3">
        <CustomInputField
          name="postalCode"
          placeholder="Postal Code *"
          onChange={(event: FormikValues) =>
            setFieldValue('postalCode', event.target.value)
          }
          required
          value={values.postalCode}
        />
      </div>
      <div className="mb-3">
        <CustomInputField
          name="state"
          placeholder="State *"
          onChange={(event: FormikValues) =>
            setFieldValue('state', event.target.value)
          }
          required
          value={values.state}
        />
      </div>
    </div>
  )
}
